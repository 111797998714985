import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import userUtils from '@/utils/UserUtils'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    component: () => import('@/views/SignIn'), 
    meta: {
      requiresAuth: false
    },        
  },

  { 
    path: '/application', 
    component: () => import('@/views/Application'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    },    
  },

  { 
    path: '/applicationmanager', 
    component: () => import('@/views/ApplicationManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/citymanager/:provinceid', 
    component: () => import('@/views/CityManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/dashboard/admin', 
    component: () => import('@/views/AdminDashboard'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  },

  /*{ 
    path: '/dashboard/teacher', 
    component: () => import('@/views/TeacherDashboard'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    },    
  },*/

  { 
    path: '/forgotpassword', 
    component: () => import('@/views/ForgotPassword'), 
    meta: {
      requiresAuth: false
    },    
  }, 

  { 
    path: '/inbox', 
    component: () => import('@/views/Inbox'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1, 2]
    },    
  },

  { 
    path: '/instrumentmanager', 
    component: () => import('@/views/InstrumentManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/lessonrequests', 
    component: () => import('@/views/LessonRequests'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    },    
  },  

  { 
    path: '/locationmanager', 
    component: () => import('@/views/LocationManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  },  

  { 
    path: '/manage', 
    component: () => import('@/views/Manage'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/messages/:chatid', 
    component: () => import('@/views/Messages'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1,2]
    },    
  },  

  { 
    path: '/noncurricularmanager', 
    component: () => import('@/views/NoncurricularManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/resetpassword/:hash', 
    component: () => import('@/views/ResetPassword'), 
    meta: {
      requiresAuth: false
    },    
  }, 

  { 
    path: '/signup/teacher', 
    component: () => import('@/views/SignUpTeacher'), 
    meta: {
      requiresAuth: false
    },    
  },

  { 
    path: '/sportmanager', 
    component: () => import('@/views/SportManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  }, 

  { 
    path: '/subjectmanager', 
    component: () => import('@/views/SubjectManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  },  

  { 
    path: '/teacheradvertisement', 
    component: () => import('@/views/TeacherAdvertisement'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    },    
  },  

  { 
    path: '/teacherprofile', 
    component: () => import('@/views/TeacherProfile'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [2]
    },    
  },  

  { 
    path: '/usermanager', 
    component: () => import('@/views/UserManager'), 
    meta: {
      requiresAuth: true,
      allowedRoles: [1]
    },    
  },

  { 
    path: '/verifyemail', 
    component: () => import('@/views/VerifyEmail'), 
    meta: {
      requiresAuth: false,
    },    
  },

  { 
    path: '/verifyteacher/:hash', 
    component: () => import('@/views/VerifyTeacher'), 
    meta: {
      requiresAuth: false,
    },    
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  let defaultPath = '/inbox';

  if (userUtils.getRole() == 1) { // admin
    defaultPath = '/dashboard/admin';
  } else if (userUtils.getRole() == 2) { // teacher
    defaultPath = '/teacherprofile';
    if (userUtils.getTeacherStatusId() == 1) {
      defaultPath = '/application';
    } 
  }

  if ( to.meta.requiresAuth) {
    // this route requires auth, check if logged in
    // if not, redirect to sign in page.
    if (!store.state.auth.authenticated) {
      next({
        path: '/',
        query: { redirect: to.fullPath }
      })
    } else {
      // now check if the signed in user is in the allowedRoles array
      if (to.meta.allowedRoles.includes(userUtils.getRole())) {
        next()
      } else {
        next({
          path: defaultPath,
          query: { redirect: to.fullPath }
        }) 
      }

    }

  } else {
    if (to.path == '/') {
      if (store.state.auth.authenticated) {
        next({
          path: defaultPath,
          query: { redirect: to.fullPath }
        })        
      } else {
        next()
      }
    } else {
      next() // make sure to always call next()!
    }
  }
});

export default router
